$theme-colors: (
  'primary': #d2281a,
);

@import '../node_modules/bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/bebas_neue/BebasNeue-Regular.woff2') format('woff2'),
    url('/fonts/bebas_neue/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.heading-font {
  font-family: 'Bebas Neue';
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Bebas Neue';
}

h1,
.h1-fs {
  font-size: 4rem;
}

h2,
.h2-fs {
  font-size: 3rem;
}

h3,
.h3-fs {
  font-size: 2.25rem;
}

h4,
.h4-fs {
  font-size: 2.75rem;
}

h5,
.h4-fs {
  font-size: 1.75rem;
}

.bs-wider-popover > .popover {
  max-width: 500px;
}
